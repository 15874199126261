import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import abi from "./abi.json";

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [whitelist, setWhitelist] = useState([
    "0x22D5094c6C66905C4096e3eAa2055119d7105D3E",
    "0x195da183bD7f32707150650c8CB82DAe9A38078C",
    "0x31F502819AB92DAA5cE04F4BC681786C9e7FBaD5",
    "0x46D5D7C25583E9d0cB115b5C67c9D3557aC27AE6",
    "0x69e3f298D328Fdb88aF4e24B3B601bBDd489BB23",
    "0xDf11662eBc3a6429F2E9ea33293ccd5dAcA88872",
    "0xA7F66D3Ff024e639191C89170463B6AC4FF1478D",
    "0xaBDBf4Bb5985aFa605e1fc53cd01A961DC94F593",
    "0x629307b2aa5f6668f641fc4c63fc8da072df8903",
    "0xC83395e0fb3D221234489224E5eB4F6c5f83f34A",
    "0x4cc0dc17e8525e35f84c6761ec1a051e47035477",
    "0xbAd22c31F21978eCEA140960bD17D0Af93d61856",
    "0x0A5ebC60Fd12BcD5402e5F99e3680588b23551d2",
    "0x23AE86eaEaCb59829722Ab41299436B1cB7753D3",
    "0xdC7a676d25A1b18bDeCd049d77f0203A63Cc2A46",
    "0x251eb912e76c3634F65Ea50A1c7B0DB463d24B5b",
    "0x90DE6D065F4CB41b46b4449aF9bd9c7BF8d38428",
    "0x99d49fd1c1f9ef322501f13a5adce6a024535943",
    "0x38ED9BC1a1E17662CafBeBd359F6E9122faB7314",
    "0xb69437065cb67923cd36BF55bC73Aa88F45Dc50f",
    "0xf6e9b43969f52021bC920c635df3933bC01aB52c",
    "0xb2dB5dc789b4F7699b00763149e037fad8f3863C",
    "0x208d0995728a5ef0740f1b3645582072742572c8",
    "0xcda3DD010855dBF166E99Cd3bC225Db259A32327",
    "0x44B256E1126F0BBB54AfDaf6fbAe998520A07063",
    "0xf837E3a96fA1020Bd688BE2b2DFc7BF2c6B25D52",
    "0x9ABed52648DA4247685F2585F0fBCBcc276bE3E1",
    "0x1f25Cf67d49DbAABf9fE3C80aafB87AB8E5AbC07",
    "0x045eA83366279aa962e9d565285A9456cf54e40F",
    "H4XUwce2EzQqLgTmiig7YHQB2vDRx4mCtjRqFo7RkwrE",
    "0x11300251b903Ba70f51262F3E49Aa7C22f81e1B2",
    "0x412558cD8f2Ed39e9a3332858B9eDDe26Ccc4F0b",
    "0x5a4Fb04BC1590f65026E7bAfb12BF0100a8cA8Be",
    "0xC1aB80F28F1F70f9f47FaD45Fe2834d3bd21d49d",
    "0x1e9b5E3b015d846D3523760dBbDA5e38E168cd07",
    "0xDc50ca6c191Fa7E7189393F0d4f2CCfeC17E453e",
    "0x79A008e58253De22c36e6c34445D9d197D735698",
    "0x97f88c8d342989b8Ad169d47a31a47da4004F48C",
    "0x301Dc8C5229F90E71cE916a23C23ec2D38fAaB4f",
    "0x3cD3bf91473C7e82Cd0a15a9295b07fC0567284E",
    "0x9aE8d8eD55AAC2eDbF8B94c5ed81C69F67E7E906",
    "0x0139d7D543e0373cB4c95D814Ba9AFe6Dc908590",
    "0x3557aFf18D4a1f72c4644947ced003df3beecBcC",
    "0x6092b98272F6ff90a3736B0b56cF14727E224B5B",
    "0xed9A280A75E50C29900E73A6f184d0fAfc4C7Df5",
    "0x48cA4E25a0c67B8eF7Da488c570eACF4DBC05b10",
    "0x86F750ce3d797385FE6cf59741Cdd206ceac88C4",
    "0xB2344cB65198520E00e03657426105CAD7C7254E",
  ]);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0x6146dfCD96eB0D069e25fC1751B51E81AAb3ff73"
    );

    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/mint.ibcdao.xyz/");
      alert("Meta Mask not detected");
    }
  }

  // Create a provider to interact with a smart contract
  async function connectWallet() {
    if (typeof window.ethereum !== "undefined") {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 10) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="mint-page">
        <Container>
          <div className="navb">
            {/* <a href="" target="_blank" rel="noreferrer"> */}
            <div className="">
              <img
                className="img-fluid  logo"
                src={require("./assets/img/logo.png").default}
                alt=""
              />
            </div>

            {/* </a> */}
            <div className=" text-center rmt">
              <div onClick={requestAccount} className="btn cusbtn">
                <span className="font-weight-bold text-uppercase">
                  {add ? add.substring(0, 15) : "Connect Wallet"}
                </span>
              </div>
            </div>
          </div>
        </Container>
        <div className="mintbox pb-5">
          <Container className="d-flex justify-content-center">
            <div className="mint">
              <div className="">
                <h1 className="mih1 text-center  text-uppercase mb-3">
                  Mint Your NFT's
                </h1>
                <p className="subtitle p-0 m-0 text-center  text-uppercase">
                  Max Per Transaction = 10
                </p>
                <p className="subtitle p-0 m-0 text-center  text-uppercase">
                  Per wallet 10
                </p>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <div className="mx-3">
                    <img
                      onClick={minus}
                      className="plus-img"
                      src={require("./assets/img/minus.png").default}
                      alt=""
                    />
                  </div>
                  <div className="display-number d-flex justify-content-center align-items-center">
                    <span className="number">{mintNumber}</span>
                  </div>
                  <div className="mx-3">
                    <img
                      onClick={plus}
                      className="plus-img"
                      src={require("./assets/img/plus.png").default}
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    onClick={mint}
                    className="mintbutton btn  text-uppercase"
                  >
                    Mint Now
                  </button>
                </div>
                <div className="text-center mt-4">
                  <h3 className="font-weight-bold bfont">
                    {" "}
                    {totalMinted} / 1250
                  </h3>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="footer">
        <Container>
          <Row>
            <Col lg="3">
              <div className="">
                <ul className="ull">
                  <li>
                    <img
                      className="img-fluid  logo"
                      src={require("./assets/img/logo.png").default}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="3">
              <div className="">
                <ul className="ull">
                  <li className="font-weight-bold">Sitemap</li>
                  <li>
                    <a href="https://www.ibcdao.xyz/about">About</a>
                  </li>
                  <li>
                    <a href="https://www.ibcdao.xyz/join">Join</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="3">
              <div className="">
                <ul className="ull">
                  <li className="font-weight-bold">Socials</li>
                  <li>
                    <a href="https://twitter.com/IBCDAO">Twitter</a>
                  </li>
                  <li>
                    <a href="https://discord.com/invite/ibcdao">Discord</a>
                  </li>
                  <li>
                    <a href="https://mirror.xyz/itsblockchain.eth">Mirror</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="3">
              <div className="">
                <ul className="ull">
                  {" "}
                  <li className="font-weight-bold">
                    {" "}
                    <a href="https://airtable.com/shrb1jTOd8NXHJxC5">
                      Report
                    </a>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <a href="https://airtable.com/shrb1jTOd8NXHJxC5">
                      {" "}
                      Bug/Request{" "}
                    </a>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <a href="https://airtable.com/shrb1jTOd8NXHJxC5">
                      Feature
                    </a>{" "}
                  </li>{" "}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
